import fetch from 'isomorphic-fetch'

import type { Menu, MenuResponse } from '../../../models/bff/menu'
import type { MenuRequest } from './logic'

import { ErrorResponse } from '../../../models/og/menu'
import { NEXTJS_API_PATH, WWW_BASE_URL } from '../../../utils/constants'

// Components will use this function to make client-side requests to /api/v2/menus/:slug
export default async function getClientSideMenu(request: MenuRequest): Promise<{ err: ErrorResponse; data: Menu }> {
  let result: Partial<MenuResponse> = {}

  let URL = `${WWW_BASE_URL}${NEXTJS_API_PATH}/menus/${request.slug}`

  if (request.brandSlug) URL += `/${request.brandSlug}`

  if (request.placeId) URL += `?place_id=${request.placeId}`

  try {
    const response = await fetch(URL)
    const statusCode = response.status
    result = await response.json()
    if (statusCode !== 200) {
      const errorResult = result as ErrorResponse
      throw Error(errorResult.message)
    }
  } catch (err) {
    console.error(`Error fetching menu ${JSON.stringify(request)}`, err)
    return { err: result as ErrorResponse, data: null }
  }
  return { err: null, data: result as Menu }
}
