import fetch from 'isomorphic-fetch'

import { Group } from '@bff/models/bff/group'
import { Brand } from '@bff/models/og/brand'
import { ErrorResponse, Product } from '@bff/models/og/menu'
import { NEXTJS_API_PATH, SERVER_API_URL, WWW_BASE_URL } from 'bff/utils/constants'

type BrandDetailsQueryParams = {
  slug: string
  placeId?: string
  products: Product[]
}

// Components will use this function to make client-side requests to /api/v2/brands/:slug
export default async function getClientSideBrandDetailsGroup(
  request: BrandDetailsQueryParams
): Promise<{ err: ErrorResponse; data: Group }> {
  let result = null
  const apiRoute = buildURL(request)
  const productRequest = request.products

  try {
    // Send all products in a single request
    const response = await fetch(apiRoute, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        products: productRequest // Send the entire products array
      })
    })

    if (!response.ok) {
      const statusCode = response.status
      throw new Error(`Failed to send products request with status code: ${statusCode}`)
    }

    result = await response.json()
    return { err: null, data: result }
  } catch (err) {
    console.error(`Error fetching brands group`, err)
    return { err: { message: err?.message, statusCode: null } as ErrorResponse, data: null }
  }
}

function buildURL(request: BrandDetailsQueryParams): URL {
  const apiRoute = new URL(`${NEXTJS_API_PATH}/brand/${request.slug}`, WWW_BASE_URL)
  if (request?.placeId) apiRoute.searchParams.append('placeId', request.placeId)
  return apiRoute
}

export async function getClientSideBrandCatalog(): Promise<{ err: ErrorResponse; data: Brand[] }> {
  let result: Partial<any> = {}

  const URL = `${SERVER_API_URL}/catalog/brands?is_ecommerce=true`

  try {
    const response = await fetch(URL)
    const statusCode = response.status
    result = await response.json()
    if (statusCode !== 200) {
      const errorResult = result as ErrorResponse
      throw Error(errorResult.message)
    }
  } catch (err) {
    console.error(`Error fetching brands catalog`, err)
    return { err: result as ErrorResponse, data: null }
  }
  return { err: null, data: result as Brand[] }
}
